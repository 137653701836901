<div id="main_content">
	<mat-toolbar id="toolbar" color="primary">
		<mat-icon class="toolbar_icon d-block mx-3" (click)="toolbarIconClick()">arrow_back</mat-icon>
		<div class="toolbar_title m_t_nowrap">Ops!</div>
		<div class="fill_remaining_space"></div>
	</mat-toolbar>

	<div class="page_container_small pt-6">
		<h2 class="m_t_title text-center">Ops. Non ho trovato questa pagina</h2>

		<div class="mt-3 text-center">
			<button class="button-unstyled d-block" (click)="goHome()"><a class="m_t_hint">Torna alla Home</a></button>
			<button (click)="goHome()" mat-icon-button color="primary">
				<mat-icon class="m_t_size_36">home</mat-icon>
			</button>
		</div>
	</div>
</div>
